/**
 * Typography
 */
//  start custom
 @import url('https://fonts.googleapis.com/css?family=Beau+Rivage&display=swap');
 @import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');
 @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

 
// SLICK
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}
/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
}


// END SLICK

* {
	box-sizing: border-box;
}
.badge{
	margin:5px;
	font-size: 14px;
	font-weight:400;
	transition:500ms cubic-bezier(0.14, 1.12, 0.67, 0.99) 0s;
	// box-shadow:3px 4px 0px 0px white;
}
.empresas-hub h3, .empresas-hub {
	font-size:18px !important;
	font-weight:500;
	text-align: -webkit-center !important;
}
body.golden-class .empresas-hub{
	padding-left:10px;
	padding-right:10px;
}
body.golden-class .empresas-hub h3,body.golden-class .empresas-hub .empresas-hub {
	font-weight:300;
}
div.empresas-hub i{
	font-size: 40px;
	margin-bottom:10px;
	color: #d3b40e !important;
}
div p.goldenrod, div h2.goldenrod, div h3.goldenrod, div h4.goldenrod, div h5.goldenrod, div h6.goldenrod, div h1.goldenrod,div span.goldenrod{
	color: goldenrod !important;
}
.accodion-style--1 ul{
	list-style:none;
}
.fadein-class{
	-webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 1.5s; /* Firefox < 16 */
	 -ms-animation: fadein 1.5s; /* Internet Explorer */
	  -o-animation: fadein 1.5s; /* Opera < 12.1 */
		 animation: fadein 1.5s;
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media only screen and (min-width:992px){
	div.beneficios-hub *{
		text-align:right !important;
	}
}
@media only screen and (max-width:991px){
	div.beneficios-hub h4{
		margin-top:25px;
	}
}
div div.beneficios-hub li{
	font-size:19px;
	color: #d3b40e !important;
	font-style: italic;
}
ul.liststyle{
	list-style:none !important;
}
.service-details-inner .sercice-details-content .details ul.liststyle li::before{
	width:0 !important;
	height:0 !important;
	top:0 !important;
}
.details ul.liststyle li::before{
	content: '✓' !important;
}
h4.title.hub-especializado{
	color: goldenrod !important;
}
header.header-area .header-wrapper{
	padding:7px 0px;
}
div.beneficios-hub h4{
	font-size:22px;
	margin-bottom:10px;
}
div.beneficios-hub ul{
	padding-left: 15px;
	list-style-position: inside;
}
.details-lab ul li,.details-lab ul{
	font-size:16px;
}
.contato-golden-visa [data-black-overlay]:before,.contacto-imagem [data-black-overlay]:before{
	z-index:2;
}

// TIMELINE

/* The actual timeline (the vertical ruler) */
.timeline {
	position: relative;
	max-width: 1200px;
	margin: 0 auto;
  }
  
  /* The actual timeline (the vertical ruler) */
  .timeline::after {
	content: '';
	position: absolute;
	width: 6px;
	background-color: #ffffff7a;
	top: 0;
	bottom: 0;
	left: 50%;
	margin-left: -3px;
  }
  
  /* Container around content */
  .container-time {
	padding: 10px 40px;
	position: relative;
	background-color: inherit;
	width: 50%;
  }
  
  /* The circles on the timeline */
  .container-time::after {
	content: '';
	position: absolute;
	width: 25px;
	height: 25px;
	right: -17px;
	background-color: goldenrod;
	border: 4px solid #FF9F55;
	top: 15px;
	border-radius: 50%;
	z-index: 1;
  }
  
  /* Place the container to the left */
  .left-time {
	left: 0;
  }
  
  /* Place the container to the right */
  .right-time {
	left: 50%;
  }
  div div.timeline h4{
	margin-bottom:0;
	font-weight:400;
	font-style: italic;
	color: white;
	text-align: left
  }
  div.contato-golden-visa input, div.contato-golden-visa textarea, div.contato-golden-visa select{
	border-color: rgba(255,255,255,0.35);
	color:white;

  }
  div.contato-golden-visa select{
	color:white;
	border-radius:5px;
  }
  div.contato-golden-visa select option,div.contato-golden-visa select optgroup{
	color:black;
  }
  .contato-golden-visa input::placeholder, .contato-golden-visa textarea::placeholder, div.contato-golden-visa select::placeholder{
	color: white;
  }
  div div.timeline span{
	color: gold !important;
	font-weight:500;
  }
  /* Add arrows to the left container (pointing right) */
  .left-time::before {
	content: " ";
	height: 0;
	position: absolute;
	top: 22px;
	width: 0;
	z-index: 1;
	right: 30px;
	border: medium solid goldenrod;
	border-width: 10px 0 10px 10px;
	border-color: transparent transparent transparent goldenrod;
  }
  
  /* Add arrows to the right container (pointing left) */
  .right-time::before {
	content: " ";
	height: 0;
	position: absolute;
	top: 22px;
	width: 0;
	z-index: 1;
	left: 30px;
	border: medium solid goldenrod;
	border-width: 10px 10px 10px 0;
	border-color: transparent goldenrod transparent transparent;
  }
  
  /* Fix the circle for containers on the right side */
  .right-time::after {
	left: -16px;
  }
  
  /* The actual content */
  .content-time {
	padding: 20px 30px;
	background-color: transparent;
	border: 1px solid goldenrod;
	position: relative;
	border-radius: 6px;
  }
  
  /* Media queries - Responsive timeline on screens less than 600px wide */
  @media screen and (max-width: 600px) {
	/* Place the timelime to the left */
	.timeline::after {
	left: 31px;
	}
	
	/* Full-width containers */
	.container-time {
	width: 100%;
	padding-left: 70px;
	padding-right: 25px;
	}
	
	/* Make sure that all arrows are pointing leftwards */
	.container-time::before {
	left: 60px;
	border: medium solid white;
	border-width: 10px 10px 10px 0;
	border-color: transparent white transparent transparent;
	}
  
	/* Make sure all circles are at the same spot */
	.left-time::after, .right-time::after {
	left: 15px;
	}
	
	/* Make all right containers behave like the left ones */
	.right-time {
	left: 0%;
	}
  }


.rn-contact-top-area .rn-address{
	background: transparent;
	border: 1px solid goldenrod;
}
.rn-contact-top-area .rn-address .inner *{
	color:white;
}
.rn-contact-top-area .rn-address .icon{
	background:#0a0a0a;
	border: 1px solid #daa520;
}

// residencia 
.tabela-residencia.text-center *{
	color:white;
	line-height:1.3
}
.tabela-residencia.text-center h5{
	text-align: -webkit-center;
	padding-bottom:10px;
	padding-top:10px;
	border: 1px solid white;
	margin-bottom:0;
}
.tabela-residencia.text-center p{
	text-align: justify;
	border: 1px solid white;
	padding:20px 15px;
}
.accordion-portugal.residencia *{
	color:white !important;
}
.accordion-portugal.residencia a{
	color:#daa520 !important;
	font-size: 17px !important;
	font-weight: 700;
}
.accordion-portugal.residencia span{
	color:#daa520 !important;
	font-size: 19px !important;
	font-weight: 700;
}

.residenc-- .custos-button-resid{
	transition: 300ms all ease;
}
.residenc-- .custos-button-resid:hover{
	transform: scale(1.025);
}
.custos-button-resid{
	background: unset;
		padding:20px 0px;
		line-height: 1.4;
		text-decoration:underline;
    font-size: 18px;
    font-weight: 700;
}
@media only screen and (max-width:768px){
	.custos-button-resid{
		font-size: 16px;
		
	}
}
// end residencia




// body
body.hub-class{
    background:linear-gradient(180deg, rgb(0, 0, 0) 0%, rgb(3 4 1) 30%, rgb(36 36 36) 60%);
	position: relative;
    z-index: 1;
}
body.hub-class::before{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url(/assets/images/v748-toon-103-min.jpg);
    content: "";
	background-size:100% 100%;
    z-index: -1;
    opacity: 0.3;
}
body.golden-class{
    background:linear-gradient(180deg, rgb(0, 0, 0) 0%, rgb(3 4 1) 30%, rgb(36 36 36) 60%);
	position: relative;
    z-index: 1;
}
body.golden-class::before{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url(/assets/images/bg/ponte-portugal.jpg);
    content: "";
	background-size:auto;
    z-index: -1;
    opacity: 0.2;
}
body.portugal-class{
    background:linear-gradient(180deg, rgb(0, 0, 0) 0%, rgb(3 4 1) 30%, rgb(36 36 36) 60%);
	position: relative;
    z-index: 1;
}
body.portugal-class::before{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url(/assets/images/v748-toon-103-min.jpg);
    content: "";
	background-size:100% 100%;
    z-index: -1;
    opacity: 0.1;
}
body.lab-class{
    background:linear-gradient(180deg, #322e05 0%, #126a62 25%, rgb(34, 34, 34) 70%);
	position: relative;
    z-index: 1;
}
body.lab-class::before{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url(/assets/images/v748-toon-103-min.jpg);
    content: "";
	background-size:100% 100%;
    z-index: -1;
    opacity: 0.2;
}
// end body


// header
@media only screen and (min-width: 992px){
	li.display-mobile{
		display:none;
	}
}

header.header-area.logoresize .logo a img{
	height: unset;
	width: 100% !important;
	max-height:67px;
}
.header-area.header--static{
	height:0;
	position:relative;
	z-index:1;
}
.grupo.header-area.header--static{
	height: auto;
	background: black;
}
header.color-black .mainmenunav ul.mainmenu>li>a{
	color:white;
	font-weight:400;
}
// end header

// serviços
.services-investimento .col-xl-3.col-lg-3{
	height:150px;
}
.services-investimento .col-xl-3.col-lg-3 *{
	z-index: 1;
}
.services-investimento .col-xl-3.col-lg-3 .service.service__style--2:hover,
.services-investimento .col-xl-3.col-lg-3 .service.service__style--2:hover *{
	position:relative;
	z-index: 2;
}
div.service.service__style--2::before {
	background-image: linear-gradient(to right, #cf9c56, #c58941);
	z-index:2;
}
div.service.service__style--2 .content h3.title {
	margin-bottom:5px;
}
@media only screen and (max-width: 575px){
	div.service.service__style--2::before {
		opacity: 0;
		visibility: hidden;
	}
}
div.service.service__style--2 .content h3.title{
	font-size:19px;
}

.text-center div.service.service__style--2{
	padding:5px 15px 20px 15px;
}
.service.service__style--2 .content p{
	display:none;
	transition: 300ms all ease;
	font-size:16px;
	line-height:1.2;
}
div.service.service__style--2 .icon{
	margin-bottom: 0;
	margin-top:10px;
}
.service.service__style--2{
	transition: 300ms all ease;
}
.service.service__style--2:hover .content p{
	display:block;
}
div.botao-brevemente a.rn-button-style--2{
	background: unset;
	cursor: unset;
	border-color:transparent;
	border-bottom: 1px solid white;
	border-radius:0;
}
div.service-home-bg div.rn-featured-service-area *{
	color:white !important;
}
.nao-exibir{
	display:none !important;
}
div.services-home h3{
	font-size:36px;
}
@media only screen and (max-width: 768px){
	div div.services-home h3{
		font-size:33px;
	}
}
.service-home-bg a.btn-transparent {
	font-size:19px;
	border: 1px solid white;
    padding: 3px 12px;
}
div.bg-count-home{
	background-image: url(/assets/images/motivar.jpg);
	background-size: 100% auto;
    background-position: 0% 25%;
}
div.service-home-bg{
	background:linear-gradient(180deg, rgb(0, 0, 0) 0%, rgb(78 143 2) 30%, rgb(255, 255, 255) 60%);
	position: relative;
    z-index: 2;
}
div.details-hub h5{
	margin-bottom:0px;
	margin-top:10px;
}
div.details-hub-2 *{
	text-align:justify;
	color: white !important;
}
div.service-home-bg::before{
	position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url(/assets/images/v748-toon-103-min.jpg);
    content: "";
	background-size:cover;
    z-index: -1;
    opacity: 0.3;
}
div.service-home-brand{
	background:linear-gradient(0deg, rgb(227 227 227) 0%, rgb(239 232 232) 30%, rgb(63, 116, 0) 75%);
	position: relative;
    z-index: 2;
}
div.service-home-brand::before{
	position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url(/assets/images/v748-toon-103-min.jpg);
    content: "";
	background-size:cover;
    z-index: -1;
    opacity: 0.3;
}
.footer-style-2 p {
    opacity: 1 !important;
    line-height: 1.3;
    color: white !important;
}
.accordion-portugal .accordion__button::after{
	position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 40px;
    height: 2px;
    background: #d4af37 !important;
    opacity: .75;
}

// porque portugal
.custos-button{
	background: linear-gradient(45deg, rgb(222 167 93), rgb(196, 136, 64));
    padding: 15px 35px;
    font-size: 18px;
    font-weight: 700;
}
@media only screen and (max-width:768px){
	.custos-button{
		font-size: 16px;
		background: unset;
		padding:20px 0px;
		line-height: 1.4;
		text-decoration:underline;
	}
}
.item-portugal{
	padding:15px;
}
.item-portugal > div{
	background: #606060;
	border-radius: 20px;
	padding:0 0px 30px 0px;	
}
.item-portugal > div .it-content{
	padding:0px 20px;
}
.item-portugal img{
	border-top-right-radius:20px;
	border-top-left-radius:20px;
	margin-bottom:20px;
	width:100%;
	height: 250px;
	object-fit:cover;
}
.item-portugal p{
	font-size:16px;
	line-height:1.45;
}
// portugal golden menu
.portugal-golden-menu .row > div:nth-child(1){
	background: #96a177;
}
.portugal-golden-menu .row > div:nth-child(2){
	background: #80c6e0;
}
.portugal-golden-menu .row > div:nth-child(3){
	background: #5cbaaa;
}
.portugal-golden-menu .row > div:nth-child(4){
	background: #ff7062;
}
.portugal-golden-menu .row > div > div{
	padding: 20px 10px;
	font-size:18px;
	font-weight:500;
	cursor: pointer;
}



// end serviços
@media only screen and (max-width:768px){
	div.slide.slide-style-2 div.inner h1.title{
		font-size:40px;
		line-height:1.3;
	}
}
div.slide.slide-style-2 .inner h1.title{
	font-size:70px;
	line-height:1.1 !important;
}
div.slide.slide-style-2 .inner p.description{
	font-size:27px;
	line-height:1.3 !important;
}
.slide.slide-style-2.slider-box-content{
	height: 650px !important;
}



.badge:hover{
	transform:scale(1.05);
}
h2.title.solange{
	font-family:'Beau Rivage';
	background: linear-gradient(to right, #eac54b, 0%,#eac54b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 5em;
    padding-left: 5px;
	font-weight:400;
}
ul.brand-style-2 img{
	opacity: 1 !important;
}
.call-to-action .inner h2{
	font-family:-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif !important;
}
h3.title.subtitulo{
	background: linear-gradient(to right, #eac54b, 0%, #eac54b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(0,0,0,0);
    line-height: 1.3;
    margin-bottom: 5vw;
    font-style: italic;
	text-align:justify;
}
.servicos-quemsomos *, .about-quemsomos *{
	color:white;
}
@media only screen and (max-width: 479px){
	.container {
		width: 95%;
	}
	.servicos-quemsomos{
		margin-top:-60px;
	}
}
.section-title h2.title.solange{
	font-size: 7em;
	line-height:1.4;
}
.section-title p{
	color:white !important;
	text-align:justify;
}
.badge-primary{
	background-color: white;
	color:black;
	font-weight:500;
}
@media only screen and (max-width:767px){
	.section-title h2.title.solange{
		font-size: 4em;
		line-height:1.4;
	}
	h3.title.subtitulo.internacional{
		font-size: 25px !important;
	}
	h5.subtitulo-quemsomos.internacional{
		font-size:20px !important;
	}
}
img {
	max-width: 100%;
}
// privacidade
.privacidade-page li{
	font-size:18px;
}
@media only screen and (max-width:768px){
	.privacidade-page li{
		font-size:16px;
	}
}

// end custom
html {
	overflow: hidden;
	overflow-y: auto;
}
body {
	overflow: hidden;
	font-size: $body-font-size;
	line-height: $body-line-height;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: $body-font;
	color: $body-color;
	font-weight: 400;
}

a {
	transition: all 0.4s ease-in-out 0s;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
dl,
dd,
menu,
ol,
ul,
table,
caption,
hr {
	margin: 0;
	margin-bottom: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: $heading-font;
	line-height: 1.4074;
	color: $heading-color;
}
h1,
.h1 {
	font-size: $h1;
}
h2,
.h2 {
	font-size: $h2;
}
h3,
.h3 {
	font-size: $h3;
}
h4,
.h4 {
	font-size: $h4;
}
h5,
.h5 {
	font-size: $h5;
}
h6,
.h6 {
	font-size: $h6;
}

@media #{$md-layout} {
	h1,
	.h1 {
		font-size: $h1 - 4;
	}
	
	h2,
	.h2 {
		font-size: $h2 - 2;
	}
	h3,
	.h3 {
		font-size: $h3 - 2;
	}
}

@media #{$sm-layout} {
	h1,
	.h1 {
		font-size: $h1 - 6;
	}
	
	h2,
	.h2 {
		font-size:$h2 - 4;
	}
	h3,
	.h3 {
		font-size: $h3 - 2;
	}
}

@media #{$large-mobile} {
	h1,
	.h1 {
		font-size: $h1 - 8;
	}
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
	font-weight: 700;
}
h4,
.h4,
h5,
.h5 {
	font-weight: 600;
}
h6,
.h6 {
	font-weight: 500;
}
a:hover,
a:focus,
a:active {
	text-decoration: none;
	outline: none;
}
a:visited {
	color: inherit;
}
input,
button,
select,
textarea {
	background: transparent;
	border: 1px solid $border-color;
	transition: all 0.4s ease-out 0s;
	color: $body-color;
	&:focus,
	&:active {
		outline: none;
		border-color: $theme-color;
	}
}
input,
select,
textarea {
	width: 100%;
	font-size: 14px;
}
input,
select {
	height: 40px;
	padding: 0 15px;
}